import React from 'react';

import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { Content } from '../components/content';

export default function() {
  const title = 'Ortodoncia y Ortopedia';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>
      <Content>
        <p>
          La alineación de los dientes y la corrección de los problemas de mordida toman fundamental importancia en
          nuestra vida cotidiana ya que afecta no sólo nuestra estética sino también funciones vitales como respiración,
          deglución, fonación y la posición postural.{' '}
        </p>
        <p>
          La ortopedia y la ortodoncia pueden ser una alternativa ideal para recuperar el equilibrio y la armonía de las
          piezas dentarias, la cavidad bucal y las funciones afectadas. Actualmente la ortodoncia se utiliza tanto en
          niños como en adultos obteniendo excelentes resultados.
        </p>
        <p>
          Ampliando el criterio aplicativo de la Ortodoncia, es la especialidad de la Odontología que se encarga del
          estudio, prevención, diagnóstico y tratamiento de las anomalías de forma, posición y relación de las
          estructuras dentomaxilofaciales. Resultando su práctica las tareas de prevención y tratamiento de las
          maloclusiones. La Ortopedia, a diferencia de la Ortodoncia actúa en forma directa sobre los maxilares (siempre
          en la niñez y preadolescencia) facilitando el estímulo del crecimiento óseo y suele ser el procedimiento
          previo a una Ortodoncia ya que amplía el espacio que albergan las piezas dentales.
        </p>
        <p>
          Hoy en día existen técnicas de última generación que utilizan distintos tipos de brackets como autoligantes,
          linguales e invisibles, indetectables a simple vista. Estos tratamientos son realizados en nuestra clínica por
          especialistas.
        </p>

        <h2>Preguntas frecuentes</h2>
        <h3>¿Hasta que edad puede realizarse una ortodoncia?</h3>
        <p>
          La Ortodoncia puede realizarse a cualquier edad. Los avances científicos y tecnológicos han facilitado la
          posibilidad exitosa de tratamientos a cualquier etapa de la vida.
        </p>
        <h3>¿Es un tratamiento doloroso?</h3>
        <p>
          No. No es un tratamiento doloroso por lo general. Se puede originar durante los primeros días de instalación
          del aparato pequeñas molestias hasta su adaptación. No obstante ello existen diversos tratamientos paliativos
          que atenúan dichas molestias.
        </p>
        <h3>¿Aparatos fijos o aparatos removibles?</h3>
        <p>
          La aparatología que será utilizada depende del diagnóstico de cada caso. Hoy en día existen técnicas nuevas
          mucho menos invasivas que permiten llegar a óptimos resultados. Pero para tener una idea es bueno saber que a
          nivel general los removibles se utilizan en edades tempranas como estímulo de crecimiento óseo (Ortopedia).
          Los fijos se utilizan en niños y adultos para el alineamiento dentario (Ortodoncia).
        </p>
        <h3>¿Aparatos estéticos o metálicos?</h3>
        <p>
          En la mayoría de los tratamientos puede usarse cualquiera de los dos tipos de aparatos. Hay casos
          excepcionales en los que por sus características los Ortodoncistas recomiendan la elección de uno de ellos.
        </p>
        <h3>¿Qué resultados arrojan estos tratamientos?</h3>
        <p>
          Como producto de un adecuado diagnóstico, tratamiento y disposición del paciente, los resultados son 100%
          efectivos, mejorando sustancialmente la funcionalidad y estética.
        </p>
      </Content>
    </Layout>
  );
}
